<template>
  <div v-show="sizeType=='full_screen'" class="event-report-new">
      <div id="wrapper" class="wrapper">
          <div class="header">
            <p>
                <span class="text">שיווק רוסמן בע"מ</span> <span dir="rtl">-</span>&rlm; <span class="text">חלוצי התעשייה 48</span>
                <span dir="rtl">,</span>&rlm; <span class="text">חיפה</span>
            </p>
            <p>
                <span class="text">טלפון</span><span dir="rtl">:</span>&rlm;<span class="text">046933555</span>&nbsp;&nbsp;<span class="text">פקס</span><span dir="rtl">:</span>&rlm;<span class="text">048767072</span>
            </p>
          </div>
          <div class="first-details">
              <div class="section section-1">
                  <div class="field">
                      <p><span class="text">שם הנפגע</span><span dir="rtl">:</span>&rlm;</p>
                      <el-input v-if="!isPrint" class="input" v-model="values.client_name" placeholder="שם מלא" />
                      <p v-else><span class="text" style="color:red;">{{values.client_name}}</span></p>
                  </div>
                  <div class="field">
                      <p><span class="text">שעת האירוע</span><span dir="rtl">:</span>&rlm;</p>
                      <input v-if="!isPrint" type="time" v-model="values.event_hour" class="input">
                      <p v-else><span class="text" style="color:red;">{{values.event_hour}}</span></p>
                  </div>
              </div>
              <div class="section section-2">
                  <div class="field">
                      <p><span class="text">תאריך האירוע</span><span dir="rtl">:</span>&rlm;</p>
                       <input v-if="!isPrint" type="date" v-model="values.event_date" class="input">
                       <p v-else><span class="text" style="color:red;">{{new Date(values.event_date).toLocaleDateString('he')}}</span></p>
                  </div>
                  <div class="field">
                    <p><span class="text">מקום האירוע</span><span dir="rtl">:</span>&rlm;</p>
                        <el-select v-if="!isPrint" class="input" v-model="values.event_location"  filterable placeholder="בחר מקום">
                            <el-option
                            v-for="brnache in brnaches_list"
                            :key="brnache"
                            :value="brnache"
                            >
                            </el-option>
                        </el-select>
                        <p v-else><span class="text" style="color:red;">{{values.event_location}}</span></p>
                  </div>
              </div>
          </div>
          <div class="second-details">
              <div class="headline">
                <p class="lead-2"><span class="text bold" >הנדון</span><span dir="rtl" class="bold">:</span>&rlm;
                 <span class="text bold"> דוח אירוע בטיחות נפגע {{values.type_event}} {{values.client_name}} </span> <span v-if="values.event_date" class="text bold">{{new Date(values.event_date).toLocaleDateString('he')}}</span>
                </p>
              </div>
              <div class="options">
                <p><span class="text lead">אנא סמן את בחירתך</span><span dir="rtl" class="lead">:</span>&rlm;</p>
                <div class="option-sections">
                    <div class="option">
                            <div class="p-field-radiobutton" style="height:50%;">
                                <RadioButton  name="type_event" value="לקוח" v-model="values.type_event" />
                                <label>
                                    <span class="text">בטיחות נפגע לקוח</span>
                                </label>
                            </div>
                            <div class="p-field-radiobutton" style="height:50%;">
                                <RadioButton  name="type_event" value="עובד" v-model="values.type_event" />
                                <label><span class="text">בטיחות נפגע עובד</span></label>
                            </div>
                    </div>
                    <div class="option">
                        <el-checkbox-group v-model="values.media">
                            <el-checkbox size="medium" label="וידאו" />
                            <el-checkbox size="medium" label="תמונות" />
                        </el-checkbox-group>

                        <p><span class="text lead">ממולא על ידי</span><span dir="rtl" class="lead">:</span>&rlm;</p>
                        <el-select v-if="!isPrint" v-model="values.kabat_name" placeholder="שם הקבט">
                                <el-option
                                    v-for="name in kabat_names" :key="name"
                                    :value="name"
                                >
                                </el-option>
                        </el-select>
                        <p v-else><span class="text" style="color:red;">{{values.kabat_name}}</span></p>
                    </div>
                </div>
              </div>
              <div class="event-describe">
                  <p><span class="text lead">תיאור האירוע</span><span dir="rtl" class="lead">:</span>&rlm;</p>
                  <el-input
                    v-if="!isPrint"
                    v-model="values.event_describe"
                    :rows="8"
                    type="textarea"
                    placeholder="תיאור האירוע..."
                    class="text-area"
                />
                <p v-else><span class="text" style="color:red; white-space: pre-wrap;">{{values.event_describe}}</span></p>
              </div>
              <div class="event-response">
                  <p><span class="text lead">תגובת העובד</span><span dir="rtl" class="lead">/</span>&rlm;<span class="text lead">לקוח</span><span dir="rtl" class="lead">:</span>&rlm;</p>
                  <el-input
                    v-if="!isPrint"
                    v-model="values.event_response"
                    :rows="7"
                    type="textarea"
                    placeholder="תגובתי ..."
                    class="text-area"
                />
                <p v-else><span class="text" style="color:red; white-space:pre-wrap;">{{values.event_response}}</span></p>
              </div>
          </div>
          <div class="signatures">
              <div class="signature">
                  <p><span class="text lead">חתימת הנפגע</span></p>
                  <SignaturePad v-if="sizeType=='full_screen' && !isPrint" idName="paint-1" sketchName="signature-1" :width="250" />
                  <img v-if="isPrint" :src="temp_images.signature_client">
              </div>
              <div class="signature">
                  <p><span class="text lead">חתימת המנהל</span></p>
                  <SignaturePad v-if="sizeType=='full_screen' && !isPrint"  idName="paint-2" sketchName="signature-2" :width="250" />
                  <img v-if="isPrint" :src="temp_images.signature_manager">
              </div>
              <div class="signature">
                  <p><span class="text lead">חתימת הקבט</span><span dir="rtl" class="lead">-</span>&rlm;<span class="text lead">חובה</span></p>
                  <SignaturePad v-if="sizeType=='full_screen' && !isPrint" idName="paint-3" sketchName="signature-3" :width="250" />
                  <img v-if="isPrint" :src="temp_images.signature_kabat">
              </div>
          </div>
          <div data-html2canvas-ignore="true" v-if="isMedia" class="upload">
                   <el-button v-if="!is_upload_pending" @click="handleUpload" class="upload-btn"  type="primary">העלאת תמונה/וידאו</el-button>
                   <el-button v-else @click="handleUpload" class="upload-btn"  type="primary" disabled>אנא המתן להעלאה...</el-button>

                   <input
                        v-show="false"
                        id="upload-image"
                        type="file"
                        @change="handleChange"
                    />
                    <div v-if="fileError" style="color:red">{{ fileError }}</div>
          </div>
      </div>
      <div class="images">
                <template
                    v-for="media in values.uploaded_media"
                    :key="media.filePath"
                    >
                    <div class="image">
                        <img v-if="media.type=='video/mp4'" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fvideo_375x375.png?alt=media&token=f522a644-0c07-4316-bafe-9ee94de22ff6">
                        <img v-else :src="media.url"/>
                        <div class="delete-bar" @click="delete_file(media)">
                            <p>מחק</p>
                        </div>
                    </div>
                </template>
      </div>
      <div class="submit">
        <el-button  @click="handle_submit" type="success">שלח טופס</el-button>
      </div>
  </div>

  <!-- mobile -->
  <div v-if="sizeType=='mobile'" class="event-report-new-mobile">
    <div class="header-mobile">
        <p>
            שיווק רוסמן בע"מ -‏ חלוצי התעשייה 48,‏ חיפה
        </p>
        <p>
            טלפון:‏046933555  פקס:‏048767072
        </p>
    </div>
    <div class="field-mobile">
        <p>שם נפגע</p>
        <el-input class="input-mobile" v-model="values.client_name" placeholder="שם מלא" />
    </div>
    <div class="field-mobile">
        <p>שעת האירוע</p>
        <input  type="time" v-model="values.event_hour" class="input-mobile">
    </div>
    <div class="field-mobile">
    <p>תאריך האירוע</p>
    <input type="date" v-model="values.event_date" class="input-mobile">
    </div>
    <div class="field-mobile">
    <p>מקום האירוע</p>
    <el-select v-if="!isPrint" class="input-mobile" v-model="values.event_location"  filterable placeholder="בחר מקום">
        <el-option
        v-for="brnache in brnaches_list"
        :key="brnache"
        :value="brnache"
        >
        </el-option>
    </el-select>
    </div>
    <div class="field-mobile">
    <p>אנא סמן את בחירתך</p>
    </div>
    <div class="option-sections-mobile">
    <div class="option-mobile">
            <div class="p-field-radiobutton" style="height:50%;">
                <RadioButton  name="type_event" value="לקוח" v-model="values.type_event" />
                <label>
                    <span class="text">בטיחות נפגע לקוח</span>
                </label>
            </div>
            <div class="p-field-radiobutton" style="height:50%;">
                <RadioButton  name="type_event" value="עובד" v-model="values.type_event" />
                <label><span class="text">בטיחות נפגע עובד</span></label>
            </div>
    </div>
    <div class="option-mobile">
        <el-checkbox-group v-model="values.media">
            <el-checkbox size="medium" label="וידאו" />
            <el-checkbox size="medium" label="תמונות" />
        </el-checkbox-group>
    </div>
    </div>
    <div class="field-mobile">
        <p>ממולא ע"י</p>
        <el-select class="input-mobile" v-if="!isPrint" v-model="values.kabat_name" placeholder="שם הקבט">
                <el-option
                    v-for="name in kabat_names" :key="name"
                    :value="name"
                >
                </el-option>
        </el-select>
    </div>
    <div class="field-mobile">
        <p class="lead">תיאור האירוע:</p>
        <el-input
        v-if="!isPrint"
        v-model="values.event_describe"
        :rows="5"
        type="textarea"
        placeholder="תיאור האירוע..."
        class="input-mobile"
    />
    </div>
    <div class="field-mobile">
                <p class="lead">תגובת העובד/לקוח:</p>
                <el-input
                v-if="!isPrint"
                v-model="values.event_response"
                :rows="5"
                type="textarea"
                placeholder="תגובתי ..."
                class="input-mobile"
            />
    </div>
    <div class="signatures-mobile">
            <div class="signature-mobile">
                <p class="lead">חתימת הנפגע</p>
                <SignaturePad idName="paint-1-mobile" sketchName="signature-1-mobile" :width="300" />
            </div>
            <div class="signature-mobile">
                <p class="lead">חתימת המנהל</p>
                <SignaturePad idName="paint-2-mobile" sketchName="signature-2-mobile" :width="300" />
            </div>
            <div class="signature-mobile">
                <p class="lead">חתימת הקב"ט (חובה)</p>
                <SignaturePad idName="paint-3-mobile" sketchName="signature-3-mobile" :width="300" />
            </div>
    </div>
    <div class="upload-mobile" v-if="isMedia">
            <el-button v-if="!is_upload_pending" @click="handleUpload" class="upload-btn-mobile"  type="primary">העלאת תמונה/וידאו</el-button>
            <el-button v-else @click="handleUpload" class="upload-btn-mobile"  type="primary" disabled>אנא המתן להעלאה...</el-button>

            <div v-if="fileError" style="color:red">{{ fileError }}</div>
    </div>
    <div class="images-mobile">
                <template
                    v-for="media in values.uploaded_media"
                    :key="media.filePath"
                    >
                    <div class="image">
                        <img v-if="media.type=='video/mp4'" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fvideo_375x375.png?alt=media&token=f522a644-0c07-4316-bafe-9ee94de22ff6">
                        <img v-else :src="media.url"/>
                        <div class="delete-bar" @click="delete_file(media)">
                            <p>מחק</p>
                        </div>
                    </div>
                </template>
    </div>
    <div class="submit-mobile">
        <el-button  @click="handle_submit" type="success">שלח טופס</el-button>
    </div>
  </div>

  <!-- just for mail -->
    <div class="form-email" id="form-email" v-show="false">
      <table
        style="width:80%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
      >
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שם הנפגע
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.client_name }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            תאריך האירוע
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ new Date(values.event_date).toLocaleDateString('he')}}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שעת האירוע
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.event_hour }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            מקום האירוע
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.event_location}}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            סוג הנפגע
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.type_event }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            מולא ע"י
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.kabat_name }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            תיאור האירוע
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.event_describe }}
          </td>
        </tr>
        <tr
          style="background-color: #dddddd;"
        >
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            תגובת העובד/לקוח
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.event_response }}
          </td>
        </tr>
        <tr v-if="values.signature_client.url">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            חתימת הנפגע
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
             <img :src="values.signature_client.url" />
          </td>
        </tr>
        <tr v-if="values.signature_manager.url" style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            חתימת המנהל
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            <img :src="values.signature_manager.url" />
          </td>
        </tr>
        <tr
          style="background-color: #dddddd;"
        >
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
             חתימת הקב"ט
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            <img :src="values.signature_kabat.url" />
          </td>
        </tr>
        <tr
          style="background-color: #dddddd;"
        >
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
              טופס PDF
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
              <a :href="values.summaryPDF.url">להורדה לחץ כאן</a>
          </td>
        </tr>

        <template  v-for="media in values.uploaded_media" :key="media.url">
                <tr>
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        תמונה/וידאו
                    </th>
                    <td v-if="media.type=='image/jpeg'" style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        <img :src="media.url" style="width:200px; height:200px;" />
                    </td>
                    <td v-if="media.type=='video/mp4'" style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                       <a :href="media.url" target="_blank"><img src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fvideo_375x375.png?alt=media&token=f522a644-0c07-4316-bafe-9ee94de22ff6" style="width:200px" /></a> 
                    </td>
                </tr>
        </template>
      </table>
    </div>
 
 <Loader v-if="isPending"/>
</template>

<script>
import { ref } from '@vue/reactivity'
import SignaturePad from '../../../signature/SignaturePad.vue'
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';
import html2pdf from "html2pdf.js";
import use_event_report_storage from '../../../../Methods/use_event_report_storage'
import {projectFirestore,projectFunctions,firebase} from '../../../../firebase/config'
import Loader from '../../../Applications/officeRequests/components/manage_side/loader/Loader.vue'
import {get_branches_from_db} from '../../../../Methods/rosman_shivuk_funcs'
import {alert} from '../../../../Methods/Msgs'
import {isDrawnOn} from '../../../../Methods/Signature_funcs'
import { computed, onMounted } from '@vue/runtime-core';
export default {
    emits:['close'],
    components:{SignaturePad,RadioButton,Checkbox,Loader},
    setup(_,{emit}){
        const temp_images=ref({
            signature_manager:'',
            signature_kabat:'',
            signature_client:''
        })
        const sizeType = ref('full_screen')
        const {url, filePath, uploadFile,upload_signature_or_pdf, deleteImage} = use_event_report_storage()
        const values = ref({
            createAt:new Date(),
            client_name:'',
            event_date:'',
            event_hour:'',
            event_location:'',
            type_event:'',
            kabat_name:"",
            event_describe:'',
            event_response:'',
            uploaded_media:[],
            media:[],
            signature_client: {
                url: "",
                filePath: ""
            },
            signature_manager: {
                url: "",
                filePath: ""
            },
            signature_kabat: {
                url: "",
                filePath: ""
            },
            summaryPDF: {
                url: "",
                filePath: ""
            },
            status:'חדש'
        })
        const isPrint = ref(false)
        const is_upload_pending = ref(false)
        const isPending = ref(false)
        const error_msg = ref(false)
        const file = ref(null);
        const fileError = ref(null);
        const kabat_names = ref(['גיל לוי'])
        const brnaches_list = ref([])

        const handle_submit = async()=>{
            if(!validation_submit()){
                alert('error','שים לב',error_msg.value)
            }
            else{
                isPending.value = true
                await signature_stores_to_url()
                isPrint.value = true
                await convertToPDF()
                await store_in_db()
                isPending.value = false
                alert('success','הצלחה','הטופס נקלט בהצלחה')
                .then(async()=>{
                    await increment()
                    //send email
                    sendEmail()
                    //close from by emit
                    emit('close')
                })
            }
        }
        const convertToPDF = async () => {
            const wrapper = document.getElementById("wrapper");
            const texts = document.querySelectorAll(".text");
            texts.forEach(text => {
                text.textContent = text.textContent.replace(/\s/g, "\u00a0");
            });
            const opt = {
                margin: 0,
                filename: `דוח-אירוע.pdf`,
                pagebreak:{ mode: '', before: '.before', after: '.after', avoid: '.avoid' },
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: { dpi: 192, letterRendering: true},
                jsPDF: {
                    unit: "in",
                    format: "a4",
                    orientation: "portrait",
                    compressPDF: true
                }
            };
            const pdf = await html2pdf().set(opt).from(wrapper).save().output("blob");
            await storePDF(pdf)
            var blobUrl = URL.createObjectURL(pdf);
            window.open(blobUrl);
        };

        const handleUpload = ()=>{
            if(!validation_before_upload()){
                alert('error','שים לב',error_msg.value)
            }else{
                document.getElementById("upload-image").click();
            }
        }
        const handleChange = async e => {
            is_upload_pending.value = true;
            const types = ["image/png", "image/jpeg", "image/jpg","video/mp4"];
            const selected = e.target.files[0];
            if (selected && types.includes(selected.type)) {
                file.value = selected;
                fileError.value = null;
                await uploadFile(file.value,values.value.event_date,values.value.event_hour,values.value.client_name);
                values.value.uploaded_media.push({
                    url: url.value,
                    filePath: filePath.value,
                    type: selected.type
                });
                
            } else {
                file.value = null;
                fileError.value =
                "סוג קובץ זה אינו נתמך, נא להעלות קובץ תמונה או PDF בלבד!";
            }
            is_upload_pending.value = false;
        };

        const delete_file = async(media)=>{
            const index = values.value.uploaded_media.findIndex(doc => (doc.filePath == media.filePath));
            await deleteImage(media.filePath);
            values.value.uploaded_media.splice(index, 1);
        }

        const validation_submit = ()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'client_name':
                        if(!value){
                            error_msg.value = 'עליך להזין שם נפגע'
                            return false
                        }
                        break;
                    case 'event_date':
                        if(!value){
                            error_msg.value = 'עליך להזין תאריך'
                            return false
                        }
                        break;
                    case 'event_hour':
                        if(!value){
                            error_msg.value = 'עליך להזין את שעת האירוע'
                            return false
                        }
                        break;
                    case 'event_location':
                        if(!value){
                            error_msg.value = 'עליך להזין את מקום האירוע'
                            return false
                        }
                        break;
                    case 'type_event':
                        if(!value){
                            error_msg.value = 'עליך לסמן את סוג הנפגע'
                            return false
                        }
                        break;
                    case 'kabat_name':
                        if(!value){
                            error_msg.value = 'עליך להזין ע"י מי טופס זה ממולא'
                            return false
                        }
                        break;
                    case 'event_describe':
                        if(!value){
                            error_msg.value = 'עליך להזין תיאור לאירוע'
                            return false
                        }
                        break;
                    case 'event_response':
                        if(!value){
                            error_msg.value = 'עליך להזין תגובה לאירוע'
                            return false
                        }
                        break;
                    case 'signature_kabat':
                        if(sizeType.value=='full_screen'){
                            if (!signatureValidate("paint-3")) {
                                error_msg.value = "חסרה חתימה של הקבט";
                                return false;
                            }
                        }
                        if(sizeType.value=='mobile'){
                            if (!signatureValidate("paint-3-mobile")) {
                                error_msg.value = "חסרה חתימה של הקבט";
                                return false;
                            }
                        }
                        
                        break;
                    case 'media':
                        if(value.length>0){
                            if(values.value.uploaded_media.length==0){
                                error_msg.value = 'עליך להעלות תמונה/וידאו'
                                return false
                            }
                        }
                        break;
                }
            }
            return true
        }

        const validation_before_upload=()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'client_name':
                        if(!value){
                            error_msg.value = 'עליך להזין שם נפגע'
                            return false
                        }
                        break;
                    case 'event_date':
                        if(!value){
                            error_msg.value = 'עליך להזין תאריך'
                            return false
                        }
                        break;
                    case 'event_hour':
                        if(!value){
                            error_msg.value = 'עליך להזין את שעת האירוע'
                            return false
                        }
                        break;
                    case 'event_location':
                        if(!value){
                            error_msg.value = 'עליך להזין את מקום האירוע'
                            return false
                        }
                        break;
                    case 'type_event':
                        if(!value){
                            error_msg.value = 'עליך לסמן את סוג הנפגע'
                            return false
                        }
                        break;
                    case 'kabat_name':
                        if(!value){
                            error_msg.value = 'עליך להזין ע"י מי טופס זה ממולא'
                            return false
                        }
                        break;
                    case 'event_describe':
                        if(!value){
                            error_msg.value = 'עליך להזין תיאור לאירוע'
                            return false
                        }
                        break;
                    case 'event_response':
                        if(!value){
                            error_msg.value = 'עליך להזין תגובה לאירוע'
                            return false
                        }
                        break;
                    case 'signature_kabat':
                        if(sizeType.value=='full_screen'){
                            if (!signatureValidate("paint-3")) {
                                error_msg.value = "חסרה חתימה של הקבט";
                                return false;
                            }
                        }
                        if(sizeType.value=='mobile'){
                            if (!signatureValidate("paint-3-mobile")) {
                                error_msg.value = "חסרה חתימה של הקבט";
                                return false;
                            }
                        }
                        
                        break;
                }
            }
            return true
        }

        const signatureValidate = paint => {
            const canvas = document.getElementById(paint);
            return isDrawnOn(canvas)
        };

        const getCanvasUrl = (id) => {
            return new Promise(resolve => {
                const signPadElement = document.getElementById(id);
                signPadElement.toBlob(async function(blob) {
                await upload_signature_or_pdf(blob,values.value.event_date,values.value.event_hour,values.value.client_name,id);
                resolve(url.value);
                });
            });
        };

        const getCanvasImg = (id) => {
            return new Promise(resolve => {
                const canvas = document.getElementById(id);
                resolve(canvas.toDataURL())
            })
        };

        const signature_stores_to_url=async()=>{
            if(sizeType.value=='full_screen'){
                if(signatureValidate('paint-1')){
                    values.value.signature_client.url = await getCanvasUrl('paint-1')
                    temp_images.value.signature_client = await getCanvasImg('paint-1')
                    values.value.signature_client.filePath = filePath.value
                }
                if(signatureValidate('paint-2')){
                    values.value.signature_manager.url = await getCanvasUrl('paint-2')
                    temp_images.value.signature_manager = await getCanvasImg('paint-2')
                    values.value.signature_manager.filePath = filePath.value
                }
                values.value.signature_kabat.url = await getCanvasUrl('paint-3')
                temp_images.value.signature_kabat = await getCanvasImg('paint-3')
                values.value.signature_kabat.filePath = filePath.value
            }
            if(sizeType.value=='mobile'){
                if(signatureValidate('paint-1-mobile')){
                    values.value.signature_client.url = await getCanvasUrl('paint-1-mobile')
                    temp_images.value.signature_client = await getCanvasImg('paint-1-mobile')
                    values.value.signature_client.filePath = filePath.value
                }
                if(signatureValidate('paint-2-mobile')){
                    values.value.signature_manager.url = await getCanvasUrl('paint-2-mobile')
                    temp_images.value.signature_manager = await getCanvasImg('paint-2-mobile')
                    values.value.signature_manager.filePath = filePath.value
                }
                values.value.signature_kabat.url = await getCanvasUrl('paint-3-mobile')
                temp_images.value.signature_kabat = await getCanvasImg('paint-3-mobile')
                values.value.signature_kabat.filePath = filePath.value
            }
        }

        const store_in_db=async()=>{
           const doc =  projectFirestore.collection('Applications').doc('eULidnp4sI9VpeciCaQG')
            .collection('Forms').doc()
            await doc.set({...values.value,id:doc.id})
        }

        const storePDF=async(blob)=>{
            await upload_signature_or_pdf(blob,values.value.event_date,values.value.event_hour,values.value.client_name,'summary_pdf');
            values.value.summaryPDF.url = url.value
            values.value.summaryPDF.filePath = filePath.value
        }

        function sendEmail() {
          projectFunctions.httpsCallable('send_email')({
              html: document.getElementById("form-email").innerHTML,
              destinations:["gill@rosman.co.il", "ravit@rosman.co.il", "rosman.mate@gmail.com"],
              from:"נפתח טופס אירוע נפגע עובד/לקוח",
              title:`סניף ${values.value.event_location}`
          })
        }

        const isMedia=ref(computed(()=>{
            if (values.value.media.length>0)return true

            return false
        }))
        
        const increment = async ()=>{
            await projectFirestore.collection('Applications').doc('eULidnp4sI9VpeciCaQG')
            .update({
                news:firebase.firestore.FieldValue.increment(1),
                total:firebase.firestore.FieldValue.increment(1)
            })
        }

        window.addEventListener('resize', () => {
            if(window.innerWidth<=600){
                sizeType.value = 'mobile'
            }
            if(window.innerWidth>600){
                sizeType.value = 'full_screen'
            }
        });
        onMounted(async()=>{
            brnaches_list.value =  await get_branches_from_db()
          if(window.innerWidth<=600){
                sizeType.value = 'mobile'
            }
            if(window.innerWidth>600){
                sizeType.value = 'full_screen'
            }
        })
        return{
            temp_images,
            brnaches_list,
            is_upload_pending,
            values,
            handle_submit,
            handleUpload,
            delete_file,
            handleChange,
            isPrint,
            isMedia,
            fileError,
            error_msg,
            isPending,
            convertToPDF,
            sizeType,
            kabat_names
        }
    }
}
</script>

<style scoped>
    .event-report-new{
        width: 100%;
        height: 100%;
        background: #fff;
        overflow:hidden;
        overflow-y: auto;
    }
    .wrapper {
        padding: 15px;
        width: 210mm;
        height: 297mm;
        margin: 0 auto;
    }
    .header{
        width: 100%;
        height: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid black;
    }
    .first-details{
        width: 100%;
        height: 15%;
        display: flex;
    }
    .section{
        width: 50%;
        height: 100%;
    }
    .section .field{
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .section .field .input{
        width: 60%;
        margin-right: 10px;
    }
    input[type="time"] {
        height: 40px;
        border: 0.5px solid lightgrey;
        border-radius: 3px;
    }
    input[type="time"]:hover {
        border: 1.1px solid lightblue;
    }
    input[type="time"]:focus {
        outline: none;
        border: 1.1px solid lightblue;
    }
    input[type="date"] {
        height: 40px;
        border: 0.5px solid lightgrey;
        border-radius: 3px;
    }
    input[type="date"]:hover {
        border: 1.1px solid lightblue;
    }
    input[type="date"]:focus {
        outline: none;
        border: 1.1px solid lightblue;
    }
    .lead{
        font-weight: bold;
        text-decoration: underline;
        height: 20px;
    }
    .lead-2{
        font-weight: bold;
        text-decoration: underline;
    }
    .bold{
        font-weight: bold;
    }
    .second-details{
        width: 100%;
        height: 60%;
    }
    .second-details .headline{
        width: 100%;
        height: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .second-details .options{
        width: 100%;
        height: 20%;
    }
    .second-details .options .option-sections{
        width: 100%;
        height: calc(100% - 20px);
        display: flex;
    }
    .second-details .options .option-sections .option{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }
    .p-field-radiobutton{
        display: flex;
        height: 50%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .event-describe{
        width: 100%;
        height: 40%;
    }
    .text-area{
        white-space: pre-wrap;
    }
    .event-response{
        width: 100%;
        height: 35%;
    }
    .signatures{
        width: 100%;
        height: 15%;
        display: flex;
    }
    .signatures .signature{
        width: calc(100% / 3);
        height: 100%;
    }
    .signatures .signature img{
        max-height: 200px;
        max-width: 200px;
    }
    .upload{
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 5px;
        margin-top: 15px;
    }
    .upload .upload-btn {
        width: 30%;
    }
    .images {
        margin: 0 auto;
        width: 210mm;
        height: fit-content;
        padding: 3px;
        display: flex;
        margin-top: 5px;
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 5px;
    }
    .images .image {
        position: relative;
        width: 100%;
        height: 100%;
        cursor: pointer;
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-left: 5px;
    }
    .images .image .delete-bar {
        margin-top: 10px;
        width: 100%;
        height: 30px;
        background-color: rgb(145, 3, 3);
        color: white;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        border-radius: 5px;
    }
    .images .image img {
        max-width: 100%;
        max-height: calc(100% - 40px);
        border-radius: 5px;
    }
    .submit{
        margin: 0 auto;
        width: 210mm;
        height: 50px;

        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
   
   /* mobile */
   .event-report-new-mobile{
       width: 100%;
       height: 100%;
       background: #fff;
       overflow:hidden;
       overflow-y: auto;
   }
   .header-mobile{
       width: 100%;
       height: 10%;
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
   }
   .field-mobile{
       margin-top: 15px;
       width: 100%;
       height: fit-content;
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
   }
   .field-mobile .input-mobile{
       margin-top: 5px;
       width: 80%;

   }
   .option-sections-mobile{
       width: 100%;
       height: fit-content;
   }
   .option-sections-mobile .option-mobile{
       margin-bottom: 10px;
       display: flex;
       justify-content: space-between;
   }
   .signatures-mobile{
       margin-top: 10px;
       width: 100%;
       height: fit-content;
   }
   .signatures-mobile .signature-mobile{
       text-align: center;
   }
   .upload-mobile{
       margin-top: 10px;
       width: 100%;
       height: fit-content;   
       text-align: center;
   }
   .upload-mobile .upload-btn-mobile{
       width: 50%
   }
    .images-mobile {
        margin: 0 auto;
        width: 100%;
        height: fit-content;
        padding: 3px;
        display: flex;
        margin-top: 5px;
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(1,1fr);
        grid-gap: 5px;
    }
    .images-mobile .image {
        position: relative;
        width: 100%;
        height: 100%;
        cursor: pointer;
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-left: 5px;
    }
    .images-mobile .image .delete-bar {
        margin-top: 10px;
        width: 100%;
        height: 30px;
        background-color: rgb(145, 3, 3);
        color: white;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        border-radius: 5px;
    }
    .images-mobile .image img {
        max-width: 100%;
        max-height: calc(100% - 40px);
        border-radius: 5px;
    }
    .submit-mobile{
        width: 100%;
        height: fit-content;
        text-align: center;
    }
   





</style>