<template>
  <div v-if="desktop" class="event-report-manage">
      <div class="header">
        <div class="search">
            <span class="p-input-icon-left" style="width:100%">
            <i class="pi pi-search" />
            <InputText
                type="text"
                v-model="search"
                placeholder="חיפוש"
                style="width:100%"
            />
            </span>
        </div>
        <div class="date-from">
            <input type="date" v-model="fromDate" />
        </div>
        <div class="date-to">
            <input type="date" v-model="toDate" />
        </div>
        <div class="clear-dates">
            <Button
            label="איפוס תאריכים"
            class="p-button-secondary"
            @click="handleClearDates"
            />
        </div>
        <div class="export-to-excel">
            <Button
            icon="pi pi-file-excel"
            class="p-button-rounded p-button-success"
            @click="handleExportToCsv"
            />
        </div>
      </div>
      <div class="dashboard">
          <div class="frame total">
            <p>סה"כ אירועים</p>
            <p>{{counter_values.total}}</p>
          </div>
          <div class="frame news">
               <p>אירועים חדשים</p>
               <p>{{counter_values.news}}</p>
          </div>
          <div class="frame old">
            <p>אירועים שטופלו</p>
            <p>{{counter_values.olds}}</p>
          </div>
        <div class="frame current">
            <p>מספר ארועים במסך נוכחי</p>
            <p>{{sorted_records.length}}</p>
        </div>
      </div>
      <div class="display-btns">
        <Button
            style="margin-left:50px"
            label="הצג חדשים"
            class="p-button-info"
            @click="handle_records('חדש')"
        />
        <Button
            style="margin-left:50px"
            label="הצג טופלו"
            class="p-button-success"
            @click="handle_records('טופל')"
        />
      </div>
      <div class="content">
           <table id="records">
               <tr>
                   <th @click="handleSortTable('נוצר בתאריך', !selectedSorted.order)">נוצר בתאריך</th>
                   <th @click="handleSortTable('תאריך', !selectedSorted.order)">תאריך האירוע</th>
                   <th>שעת האירוע</th>
                   <th @click="handleSortTable('שם הנפגע', !selectedSorted.order)">שם הנפגע</th>
                   <th @click="handleSortTable('מקום האירוע', !selectedSorted.order)">מקום האירוע</th>
                   <th @click="handleSortTable('סוג הנפגע', !selectedSorted.order)">סוג הנפגע</th>
                   <th @click="handleSortTable('ממלא האירוע', !selectedSorted.order)">ממלא האירוע</th>
                   <th>סטטוס</th>
                   <th>מדיה</th>
                   <th>מסמך</th>
                   <th><Checkbox v-model="checkeAll" :binary="true" /></th>
               </tr>
                <h1  style="text-align:center;" v-if="sorted_records.length==0 && !isPending">אין רשומות</h1>
               <template v-for="event in sorted_records" :key="event.id">
                   <tr>
                       <td>{{event.createAt.toDate().toLocaleDateString('he')}}</td>
                       <td>{{new Date(event.event_date).toLocaleDateString('he')}}</td>
                       <td>{{event.event_hour}}</td>
                       <td>{{event.client_name}}</td>
                       <td>{{event.event_location}}</td>
                       <td>{{event.type_event}}</td>
                       <td>{{event.kabat_name}}</td>
                       <td v-if="event.status=='חדש'" style="color:green;">{{event.status}}</td>
                       <td v-if="event.status=='טופל'" style="color:red;">{{event.status}}</td>
                       <td><i id="media" class="pi pi-images" @click="handleShowMedia(event)"></i></td>
                       <td><i id="pdf" class="pi pi-file-pdf" @click="handleSummaryPDF(event.summaryPDF)"></i></td>
                       <td><Checkbox name="record" :value="event.id" v-model="check_records"/></td>
                   </tr>
               </template>

           </table>
      </div>
  </div>

   <OnlyForDesktop v-if="!desktop" />

  <Loader v-if="isPending"/>
  <Dialog header="תמונות וחתימות" v-model:visible="showMedia">
      <div class="media-container">
          <div class="photos-and-signatures">
            <div class="box photos">
                <template v-for="media in currentDetail.uploaded_media" :key="media.filePath">
                    <div>
                        <img v-if="media.type != 'video/mp4'" :src="media.url">
                        <a v-else :href="media.url" target="_blank">
                            <img  src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fvideo_375x375.png?alt=media&token=f522a644-0c07-4316-bafe-9ee94de22ff6">
                        </a>
                    </div>
                 </template>   
                 <h1 v-if="currentDetail.uploaded_media.length==0">אין תמונות להצגה</h1>
            </div>
            <div class="box signatures">
                <div>
                    <h4>חתימת הנפגע עובד/לקוח</h4>
                    <img v-if="currentDetail.signature_client.url" :src="currentDetail.signature_client.url">
                    <p v-else style="color:red">לא קיימת חתימה</p>
                </div>
                <div>
                    <h4>חתימת המנהל</h4>
                    <img v-if="currentDetail.signature_manager.url" :src="currentDetail.signature_manager.url">
                    <p v-else style="color:red">לא קיימת חתימה</p>
                </div>
                <div>
                    <h4>חתימת הקב"ט</h4>
                    <img v-if="currentDetail.signature_kabat.url" :src="currentDetail.signature_kabat.url">
                    <p v-else style="color:red">לא קיימת חתימה</p>
                </div>
            </div>
          </div>
          <div class="actions">
                <Dropdown v-model="currentDetail.status" :options="status_options" placeholder="שנה סטטוס" />
                <Button style="margin-right:5px;" label="עדכן" class="p-button-secondary" @click="handle_change_status" />
          </div>
      </div>
  </Dialog>
    <Dialog
            header="חלון מחיקה"
            v-model:visible="showDeleteDialog"
            :style="{ width: '30vw' }"
            position="bottom"
        >
            <p class="p-m-0">האם את/ה בטוח שברצונך למחוק את הרשומות המסומנות?</p>
            <template #footer>
            <Button
                label="לא"
                @click="handleDeleteChecks('לא')"
                class="p-button-success"
            />
            <Button
                label="כן"
                @click="handleDeleteChecks('כן')"
                class="p-button-danger"
            />
            </template>
    </Dialog>
</template>

<script>
import InputText from "primevue/inputtext";
import Dropdown from 'primevue/dropdown';
import Dialog from "primevue/dialog";
import OnlyForDesktop from '../../../Not_responsible/OnlyForDesktop.vue'
import { ref } from '@vue/reactivity';
import {projectFirestore} from '../../../../firebase/config'
import Checkbox from 'primevue/checkbox';
import { computed, onMounted, onUnmounted, watch } from '@vue/runtime-core';
import Loader from '../../officeRequests/components/manage_side/loader/Loader.vue'
import {alert} from '../../../../Methods/Msgs'
import XLSX from "xlsx";
import {decrement,update_status_counter} from '../../../../Methods/bigud_funcs'

export default {
    components:{InputText,Dialog,Checkbox,Loader,Dropdown,OnlyForDesktop},
    setup(){
        const desktop = ref(true)
        const counter_values = ref({
          news:0,
          olds:0,
          total:0,
        })
        const search = ref('')
        const fromDate = ref('')
        const toDate = ref('')
        const records = ref([])
        const isPending = ref(false)
        const showMedia = ref(false)
        const currentDetail = ref(null)
        const current_status = ref('')
        const checkeAll = ref(false)
        const check_records = ref([])
        const showDeleteDialog = ref(false)

        const status_options = ref(['חדש','טופל'])

        const handleExportToCsv=()=>{
          let rows = JSON.parse(JSON.stringify(sorted_records.value)) 
            rows.forEach(row=>{
                row['תאריך האירוע']=new Date(row.event_date).toLocaleDateString('he').split('.').join('/')
                row['שעת האירוע']=row.event_hour
                row['שם הנפגע']=row.client_name
                row['מקום האירוע']=row.event_location
                row['סוג הנפגע']=row.type_event
                row['ממלא האירוע']=row.kabat_name
                row['סטטוס']=row.status
              
                delete row.id
                delete row.event_date
                delete row.event_hour
                delete row.client_name
                delete row.event_location
                delete row.type_event
                delete row.kabat_name
                delete row.status
                delete row.createAt
                delete row.uploaded_media
                delete row.signature_kabat
                delete row.media
                delete row.event_response
                delete row.signature_client
                delete row.signature_manager
                delete row.summaryPDF
                delete row.event_describe

            })
            let workSheet = XLSX.utils.json_to_sheet(rows);
            let workbook = XLSX.utils.book_new();
            workbook.Workbook = {};
            workbook.Workbook.Views = [];
            workbook.Workbook.Views[0] = {};
            workbook.Workbook.Views[0].RTL = true;

            XLSX.utils.book_append_sheet(workbook, workSheet, "רשימת אירועי נפגעים");
            XLSX.writeFile(workbook, "אירוע_נפגע.xlsx");
        }

        const handle_change_status = async()=>{
            if(current_status.value!=currentDetail.value.status){
                await projectFirestore.collection('Applications')
                .doc('eULidnp4sI9VpeciCaQG').collection('Forms')
                .doc(currentDetail.value.id).set({
                    status:currentDetail.value.status
                },{merge:true})

                alert('success',
                'הצלחה',
                `סטטוס אירוע זה עודכן ל${currentDetail.value.status}`)
                .then(async()=>{
                    if(currentDetail.value.status=='חדש'){
                        await update_status_counter('olds','news','eULidnp4sI9VpeciCaQG')
                    }
                    if(currentDetail.value.status=='טופל'){
                        await update_status_counter('news','olds','eULidnp4sI9VpeciCaQG')
                    }
                    const index = records.value.findIndex(event=>event.id==currentDetail.value.id)
                    if(index!=-1){
                        records.value.splice(index,1)
                    }
                    showMedia.value = false
                })
            }
        }

        const handleDeleteChecks=async(option)=>{
            if(option=='לא'){
                showDeleteDialog.value=false
                check_records.value=[]
                checkeAll.value=false
            }
            if(option=='כן'){
                isPending.value = true
                for (const id of check_records.value){
                    await delete_record(id)
                    const index = records.value.findIndex(event=>event.id == id)
                    if(index!=-1){
                      if(records.value[index].status=='חדש'){
                          await decrement('news','eULidnp4sI9VpeciCaQG')
                      }
                      if(records.value[index].status=='טופל'){
                          await decrement('olds','eULidnp4sI9VpeciCaQG')
                      }
                      records.value.splice(index,1)
                    }
                }
                showDeleteDialog.value=false
                check_records.value=[]
                isPending.value=false
                checkeAll.value=false
            }
        }

        const delete_record=async(id)=>{
            await projectFirestore.collection('Applications').doc('eULidnp4sI9VpeciCaQG')
            .collection('Forms').doc(id).delete()
        }

        const handleShowMedia = (event)=>{
            showMedia.value = !showMedia.value
            currentDetail.value = event
            current_status.value = currentDetail.value.status
        }

        const handleSummaryPDF = (pdf)=>{
            window.open(pdf.url)
        }

        const handleSortTable = (name, order) => {
            selectedSorted.value.name = name;
            selectedSorted.value.order = order;
        };
        const selectedSorted = ref({
            name: "",
            order: false
        });
        const sorted_records = ref(computed(() => {
            if (selectedSorted.value.name == "")return filter_records_by_search.value;
            if (selectedSorted.value.name == "תאריך") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  stringTodate(a.event_date) >  stringTodate(b.event_date)
                        ? 1
                        : stringTodate(b.event_date)  > stringTodate(a.event_date)
                        ? -1
                        : 0;
                    }
                    return stringTodate(a.event_date) > stringTodate(b.event_date) 
                    ? -1
                    : stringTodate(b.event_date)   > stringTodate(a.event_date) 
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "נוצר בתאריך") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  new Date(a.createAt.seconds * 1000) >  new Date(b.createAt.seconds * 1000)
                        ? 1
                        : new Date(b.createAt.seconds * 1000)  > new Date(a.createAt.seconds * 1000)
                        ? -1
                        : 0;
                    }
                    return new Date(a.createAt.seconds * 1000) > new Date(b.createAt.seconds * 1000)
                    ? -1
                    : new Date(b.createAt.seconds * 1000)  > new Date(a.createAt.seconds * 1000)
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "שם הנפגע") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.client_name > b.client_name
                        ? 1
                        : b.client_name  > a.client_name
                        ? -1
                        : 0;
                    }
                    return a.client_name > b.client_name
                    ? -1
                    : b.client_name  > a.client_name
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "סוג הנפגע") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.type_event > b.type_event
                        ? 1
                        : b.type_event  > a.type_event
                        ? -1
                        : 0;
                    }
                    return a.type_event > b.type_event
                    ? -1
                    : b.type_event  > a.type_event
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "מקום האירוע") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.event_location > b.event_location
                        ? 1
                        : b.event_location  > a.event_location
                        ? -1
                        : 0;
                    }
                    return a.event_location > b.event_location
                    ? -1
                    : b.event_location  > a.event_location
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "ממלא האירוע") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.kabat_name > b.kabat_name
                        ? 1
                        : b.kabat_name  > a.kabat_name
                        ? -1
                        : 0;
                    }
                    return a.kabat_name > b.kabat_name
                    ? -1
                    : b.kabat_name  > a.kabat_name
                    ? 1
                    : 0;
                });
            }
        }));
        const filter_records_by_search = ref(computed(()=>{
             if(search.value == '') {
                 if(fromDate.value && toDate.value){
                     return records.value.filter(record=>{
                        if(stringTodate(fromDate.value)<=stringTodate(record.event_date) &&
                        stringTodate(toDate.value)>=stringTodate(record.event_date) ){
                            return record
                        }
                    })
                 }
                 return records.value;
             }else{
                let results = records.value.filter(record=>{
                    if(new Date(record.event_date).toLocaleDateString('he').includes(search.value)) return record
                    if (record.event_hour.includes(search.value)) return record;
                    if (record.client_name.includes(search.value)) return record;
                    if (record.event_location.includes(search.value)) return record;
                    if (record.kabat_name.includes(search.value)) return record;
                    if (record.type_event.includes(search.value)) return record;
                })
                if(fromDate.value && toDate.value){
                    return results.filter(record=>{
                        if(stringTodate(fromDate.value)<=stringTodate(record.event_date) &&
                        stringTodate(toDate.value)>=stringTodate(record.event_date) ){
                            return record
                        }
                    })
                }else{
                    return results
                }
             }
        }))
        const handle_records = async(status)=>{
            isPending.value = true
            localStorage.setItem("Evenet_report_status",status);
            const docs = await projectFirestore.collection('Applications').doc('eULidnp4sI9VpeciCaQG')
            .collection('Forms').where('status','==',status).orderBy('createAt','desc').get()
            records.value = docs.docs.map(doc=>doc.data())
            isPending.value = false
        }
        
        const handleClearDates=()=>{
            fromDate.value=''
            toDate.value=''
        }

         // for dates
        const stringTodate=(dateString)=>{
            const d=new Date(dateString)
            d.setHours(0,0,0,0)
            return d
        }

        const initial = async()=>{
            let status='חדש'
            if(localStorage.getItem('Evenet_report_search')){
                    search.value = localStorage.getItem('Evenet_report_search')
            }
            if(localStorage.getItem("Evenet_report_status")){
                    status=localStorage.getItem("Evenet_report_status")
            }
            await handle_records(status)
        }
        let counter_unsub
        const counter_records_RT=()=>{
            counter_unsub = projectFirestore.collection('Applications').doc('eULidnp4sI9VpeciCaQG')
            .onSnapshot(doc=>{
                counter_values.value = doc.data()
            })
        }

        watch(search,()=>{
            localStorage.setItem("Evenet_report_search",search.value);
        })
        watch(checkeAll,()=>{
            if(checkeAll.value){
                check_records.value=sorted_records.value.map(event=>event.id)
            }
            else{
                check_records.value=[]
            }
        })
        watch(check_records,()=>{
            if(check_records.value.length>0){
                showDeleteDialog.value=true
            }
            else {
                showDeleteDialog.value=false
            }
        })
        
        window.addEventListener('resize', ()=>{
            if(window.innerWidth<600){
                desktop.value = false
            }else{
                desktop.value = true
            }
        });
        onMounted(async()=>{
            if(window.innerWidth<600){
                desktop.value = false
            }else{
                desktop.value = true
            }
            await initial()
            counter_records_RT()
        })

        onUnmounted(()=>{
            window.removeEventListener('resize', ()=>{
                if(window.innerWidth<600){
                    desktop.value = false
                }else{
                    desktop.value = true
                }
            });
            if(counter_unsub){
                counter_unsub()
            }
        })

        return{
            desktop,
            counter_values,
            handle_records,
            handleClearDates,
            handleSortTable,
            handleShowMedia,
            handleDeleteChecks,
            handleSummaryPDF,
            handle_change_status,
            handleExportToCsv,
            sorted_records,
            fromDate,
            toDate,
            search,
            records,
            isPending,
            filter_records_by_search,
            selectedSorted,
            showMedia,
            currentDetail,
            checkeAll,
            check_records,
            showDeleteDialog,
            status_options
        }
    }
}
</script>

<style scoped>
    .event-report-manage{
        width: 100%;
        height: 100%;
        background: #fff;
    }
    .header{
        padding: 1.5rem;
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: space-between;
        align-items: center; 
    }
    input[type="date"] {
        height: 35px;
        border: 0.5px solid lightgrey;
        border-radius: 3px;
    }
    input[type="date"]:hover {
        border: 1.1px solid lightblue;
    }
    input[type="date"]:focus {
        outline: none;
        border: 1.1px solid lightblue;
    }
    .dashboard{
        width: 100%;
        height: 5%;
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
    }
    .dashboard .frame{
        width: 15%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 10px;
    }
    .dashboard .total{
        background-color: teal;
    }
    .dashboard .news{
        background-color: #027ABC;
    }
    .dashboard .old{
        background-color: #5E8F32;
    }
    .dashboard .current{
        background-color: grey;
    }
    .display-btns{
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .content{
        position: relative;
        width: 100%;
        height: 75%;
        overflow: hidden;
        overflow-y: auto;
    }
    #records {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }
    #records td, #records th {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: center;
    }
    #records tr:nth-child(even){
        background-color: #f2f2f2;
    }
    #records tr:hover {
        background-color: #ddd;
    }
    #records th {
        cursor: pointer;
        padding-top: 12px;
        padding-bottom: 12px;
        background-color: #04AA6D;
        color: white;
    }
    i{
        cursor: pointer;
    }
    .media-container{
        width: 70vw;
        height: 60vh;
    }
    .media-container .photos-and-signatures{
        width: 100%;
        height: 90%;
        display: flex;
    }
    .media-container .photos-and-signatures .box{
        width: 50%;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 5px;
    }
    .media-container .photos-and-signatures .box div{
        width: 100%;
    }
    .media-container .photos-and-signatures .box div img{
        max-height: 100%;
        max-width: 100%;
    }
    .media-container .actions{
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
    }





</style>